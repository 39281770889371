import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faTimesCircle, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const InvoiceTable = ({ invoices, selectedInvoiceId, onRowClick, openInvoiceDetails }) => {
  return (
    <table className="invoice-table">

      <tbody>
        {invoices.map((invoice) => (
          <tr 
            className={selectedInvoiceId === invoice.id ? 'selected-row' : ''}
            key={invoice.id} onClick={() => onRowClick(invoice.payPageLink)}>
            <td>
              <FontAwesomeIcon 
                icon={faSearchPlus} 
                className="clickable-icon blue-icon" 
                onClick={(e) => {
                  e.stopPropagation();
                  openInvoiceDetails(invoice.id);
                }} 
                title="Details bekijken"
              />
            </td>
            <td>{invoice.id}</td>
            <td>€{invoice.bedrag.toFixed(2)}</td>
            <td>{invoice.date}</td>
            <td>
              {invoice.status === 'completed' ? (
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" title="Factuur betaald" />
              ) : (
                invoice.status === 'due' ? (
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500" title="Factuur niet betaald en betaaltermijn verlopen " />
                ):(
                  <FontAwesomeIcon icon={faTimesCircle} className="text-red-500" title="Factuur niet betaald" />
                )
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;